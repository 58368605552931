import { Typography, TypographyProps } from '@mui/material'
import React from 'react'

export function TruncatedTypography({ children, ...rest }: TypographyProps) {
  return (
    <Typography
      {...rest}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {children}
    </Typography>
  )
}
