import { Grid2 } from '@mui/material'
import React from 'react'
import { CustomPalette } from '../../../app/Theme'
import { HospitalizationDetail } from '../components/HospitalizationDetail/HospitalizationDetail'
import { HospitalizationSchedule } from '../components/HospitalizationSchedule/HospitalizationSchedule'

export function HospitalizationDetailPage() {
  return (
    <Grid2 container>
      <Grid2 container flexGrow={1}>
        <HospitalizationSchedule />
      </Grid2>
      <Grid2
        container
        width="220px"
        borderLeft={`1px solid ${CustomPalette.master.black}1A`}
        height="calc(100vh - 56px)"
        flexDirection="column"
        sx={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <HospitalizationDetail />
      </Grid2>
    </Grid2>
  )
}
