import { type SuiteApi } from '@boommed-suite/contracts'
import {
  Guid,
  HttpRequestError,
  isError,
  NetworkError,
  StatusCode,
} from '@boommed-suite/typescript-crossplatform'
import { zodResolver } from '@hookform/resolvers/zod'
import { mdiClose } from '@mdi/js'
import MDIIcon from '@mdi/react'
import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import { useInjection } from 'inversify-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BoommedService } from '../../../../../domain/services/BoommedService'
import { Crashlytics } from '../../../../../domain/services/Crashlytics'
import { useAppNavigate } from '../../../../app/AppRouter'
import { useAppContext } from '../../../../app/contexts/AppContext'
import { useNotificationContext } from '../../../../app/contexts/NotificationContext'
import { theme } from '../../../../app/Theme'
import { CustomMUIForm } from '../../../../components/CustomMUIForm/CustomMUIForm'
import { SuiteButton } from '../../../../components/SuiteButton/SuiteButton'
import { styles } from '../../../ClientsPage/components/NewClientForm/NewClientForm.styles'
import {
  NewClientForm,
  newClientFormSchema,
  NewClientFormSchema,
} from '../NewClientForm/NewClientForm'

const NewClientHeader = () => {
  const { t } = useTranslation()
  const navigate = useAppNavigate()

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack direction="row" sx={styles.header}>
      <Typography variant="h4" flexGrow={1} fontWeight="bold">
        {t('clients_add')}
      </Typography>
      <Box sx={styles.closeButton} onClick={onClose}>
        <MDIIcon path={mdiClose} size={0.75} />
      </Box>
    </Stack>
  )
}

const NewClientFooter = () => {
  const navigate = useAppNavigate()
  const { t } = useTranslation()

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack
      sx={styles.footerContainer}
      direction="row"
      spacing={theme.spacing(2)}
    >
      <Box sx={styles.footerDiscardContainer}>
        <Button variant="text" onClick={onClose}>
          {t('discard')}
        </Button>
      </Box>

      <SuiteButton variant="contained" type="submit">
        {t('clients_new_create')}
      </SuiteButton>
    </Stack>
  )
}

export function AddClientPage() {
  const { t } = useTranslation()
  const { menu } = useAppContext()
  const boommedService = useInjection(BoommedService)
  const { openNotification } = useNotificationContext()
  const crashlytics = useInjection(Crashlytics)
  const navigate = useAppNavigate()
  const { action: routeAction } = useParams()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const addClient = useCallback(async (values: SuiteApi.AddClientRequest) => {
    if (menu?.items?.clients?._links?.new) {
      const [result, error] = await boommedService.fetch(
        menu.items.clients._links.new,
        values,
      )

      if (
        isError(HttpRequestError)(error) &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        error.statusCode === StatusCode.BAD_REQUEST
      ) {
        openNotification({
          severity: 'error',
          text: t('client_duplicate_error'),
        })
        return
      }

      if (isError(NetworkError)(error)) {
        setUnexpectedError(error)
        return
      }

      if (isError(Error)(error) || isError(HttpRequestError)(error)) {
        crashlytics.error(error)
        openNotification({
          severity: 'error',
          text: t('client_add_error'),
        })
        return
      }

      if (result) {
        navigate(-1)
      }
    }
  }, [])

  const onSubmit = (values: NewClientFormSchema) => {
    const newClient: SuiteApi.AddClientRequest = {
      ...values,
      userId: Guid.New().toString(),
      externalLink: 'some external link',
    }
    void addClient(newClient)
  }

  return (
    <Drawer
      anchor="right"
      open={routeAction === 'add'}
      sx={styles.drawer}
      variant="temporary"
    >
      <Box sx={styles.container}>
        <NewClientHeader />
        <CustomMUIForm
          resolver={zodResolver(newClientFormSchema)}
          onSubmit={onSubmit}
        >
          <NewClientForm />
          <NewClientFooter />
        </CustomMUIForm>
      </Box>
    </Drawer>
  )
}
