import { CustomPalette } from '../../../../app/Theme'

const HEADER_HEIGHT = '40px'
const FOOTER_HEIGHT = '56px'
const CONTAINER_MARGIN = '8px'

const CONTAINER_WIDTH = '480px'

export const styles = {
  drawer: {
    '& .MuiDrawer-root': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      height: `calc(100% - (${CONTAINER_MARGIN} * 2))`,
      overflow: 'hidden',
    },
    '& .MuiDrawer-paper': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '& .MuiDrawer-paper > .MuiStack-root': {
      height: '100%',
      padding: CONTAINER_MARGIN,
      margin: CONTAINER_MARGIN,
      borderRadius: '6px',
      boxShadow: '0 2px 10px 0px rgba(0,0,0,0.12)',
    },
  },
  container: {
    display: 'grid',
    gridTemplateRows: `${HEADER_HEIGHT} auto ${FOOTER_HEIGHT}`,
    overflow: 'hidden',

    margin: CONTAINER_MARGIN,
    width: CONTAINER_WIDTH,
    borderRadius: '6px',
    boxShadow: '0 2px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: 'white',
  },
  header: {
    borderRadius: '6px 6px 0 0',
    height: HEADER_HEIGHT,
    margin: CONTAINER_MARGIN,
  },
  closeButton: {
    background: CustomPalette.master.grey,
    borderRadius: '38px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    overflowY: 'auto',
    margin: CONTAINER_MARGIN,
    paddingTop: CONTAINER_MARGIN,

    minHeight: `calc(100vh - ${FOOTER_HEIGHT} - ${HEADER_HEIGHT} - (${CONTAINER_MARGIN} * 2))`,
    height: '100%',
  },
  footerContainer: {
    position: 'sticky',
    bottom: 0,
    borderRadius: '0 0 6px 6px',
    height: FOOTER_HEIGHT,
    padding: '16px',

    alignItems: 'center',

    backgroundColor: CustomPalette.master.lightGrey,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  footerDiscardContainer: {
    flexGrow: 1,
    button: {
      textTransform: 'capitalize',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  footerSaveButtonDisabled: {
    textTransform: 'capitalize',
    backgroundColor: CustomPalette.master.primary.main,
    opacity: 0.5,
    color: '#fff',
  },
  footerHospitalizationLabel: {
    '& .MuiFormControlLabel-label': {
      textTransform: 'capitalize',
      fontWeight: '450',
    },
  },
}
