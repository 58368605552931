import { TextField } from '@mui/material'
import React from 'react'
import { FieldError, FieldValues, Path, useFormContext } from 'react-hook-form'

type CustomFormTextFieldProps<TFieldValues extends FieldValues> = Omit<
  React.ComponentProps<typeof TextField>,
  'name' | 'label' | 'required'
> & {
  name: Path<TFieldValues>
  label?: string
  required?: boolean
}

export function CustomFormTextField<
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  required = false,
  ...rest
}: CustomFormTextFieldProps<TFieldValues>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>()

  const error = errors[name] as FieldError | undefined

  return (
    <TextField
      {...rest}
      {...register(name)}
      name={name}
      label={label}
      required={required}
      error={Boolean(error)}
      helperText={error?.message ?? ''}
    />
  )
}
