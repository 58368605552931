import { mdiScale } from '@mdi/js'
import Icon from '@mdi/react'
import { Box, Stack, Typography } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useService } from '../../../../hooks/useService'

interface Weight {
  day: number
  value: number
}

export function HospitalizationWeightHistory() {
  const { t } = useTranslation()

  const { data: weight = [] } = useService<Weight[]>(
    {
      service: async () => {
        return Promise.resolve<Weight[]>([
          { day: 1, value: 14 },
          { day: 2, value: 10 },
          { day: 3, value: 11 },
          { day: 4, value: 12 },
          { day: 5, value: 16 },
          { day: 6, value: 20 },
        ])
      },
    },
    [],
  )

  const days = weight.map((t) => t.day)
  const Weights = weight.map((t) => t.value)

  return (
    <Stack spacing={2} paddingX="12px" direction="column">
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon path={mdiScale} size={1} />
        <Typography fontWeight={700} lineHeight="16px" variant="h5">
          {t('hospitalization_weight')}
        </Typography>
      </Stack>
      <Box width="195px">
        <LineChart
          xAxis={[{ data: days }]}
          series={[
            {
              data: Weights,
            },
          ]}
          width={265}
          height={175}
          sx={{
            left: '-30px',
            bottom: '40px',
            position: 'relative',
            '& .MuiChartsAxis-tickLabel': { fontSize: '8px !important' },
          }}
        />
      </Box>
    </Stack>
  )
}
