import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import {
  Controller,
  FieldError,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form'

type CustomFormDateTimePickerProps<TFieldValues extends FieldValues> = Omit<
  React.ComponentProps<typeof DateTimePicker>,
  'name' | 'label' | 'required'
> & {
  name: Path<TFieldValues>
  label?: string
  required?: boolean
}

export function CustomFormDateTimePicker<
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  required = false,
  ...rest
}: CustomFormDateTimePickerProps<TFieldValues>) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>()

  const error = errors[name] as FieldError | undefined

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            {...rest}
            {...register(name)}
            name={name}
            label={label}
            onChange={(date) => {
              field.onChange(date ? date.valueOf() : null)
            }}
            slotProps={{
              textField: {
                required,
                error: Boolean(error),
                helperText: error?.message ?? '',
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  )
}
