import WestIcon from '@mui/icons-material/West'
import { Grid2, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { styles } from './HospitalizationDetailHeader.styles'

export const HospitalizationDetailHeader = () => {
  const { t } = useTranslation()

  return (
    <Grid2 sx={styles.root} container>
      <Grid2 alignItems="center" container spacing={2}>
        <Grid2>
          <IconButton sx={styles.iconContainer}>
            <WestIcon sx={styles.icon} />
          </IconButton>
        </Grid2>
        <Grid2 alignContent="center">
          <Typography variant="h4" textTransform="capitalize">
            {t('hospitalization')}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2
        flexGrow={1}
        alignItems="center"
        alignContent="center"
        textAlign="center"
      >
        <div>Date selector</div>
      </Grid2>
      <Grid2>
        <div>Actions button</div>
      </Grid2>
    </Grid2>
  )
}
