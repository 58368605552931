import { Divider, Stack } from '@mui/material'
import React from 'react'
import { HospitalizationClientDetail } from './HospitalizationClientDetail'
import { HospitalizationGeneralInfo } from './HospitalizationGeneralInfo'
import { HospitalizationPetDetail } from './HospitalizationPetDetail'
import { HospitalizationProblems } from './HospitalizationProblems'
import { HospitalizationTemperatureHistory } from './HospitalizationTemperatureHistory'
import { HospitalizationWeightHistory } from './HospitalizationWeightHistory'

export function HospitalizationDetail() {
  return (
    <Stack divider={<Divider flexItem />} spacing={2}>
      <HospitalizationPetDetail />
      <HospitalizationClientDetail />
      <HospitalizationProblems />
      <HospitalizationTemperatureHistory />
      <HospitalizationWeightHistory />
      <HospitalizationGeneralInfo />
    </Stack>
  )
}
