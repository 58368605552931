import { mdiCakeVariant, mdiDog, mdiScale } from '@mdi/js'
import Icon from '@mdi/react'
import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'

function HospitalizationData({ icon, value }: { icon: string; value: string }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Icon path={icon} size={1} />
      <Typography variant="h5" lineHeight="16px">
        {value}
      </Typography>
    </Stack>
  )
}

export function HospitalizationPetDetail() {
  return (
    <Stack padding="12px 12px 0 12px" spacing={2}>
      <Stack direction="row" spacing={2}>
        <Avatar
          alt="Pet name"
          src="https://image.petmd.com/files/styles/863x625/public/CANS_dogsmiling_379727605.jpg"
          sx={{ width: '32px', height: '32px' }}
        />
        <Typography variant="h5" fontWeight={700} lineHeight="16px">
          Some very big pet name
        </Typography>
      </Stack>
      <HospitalizationData icon={mdiDog} value="Species - Breed" />
      <HospitalizationData icon={mdiScale} value="10 kg" />
      <HospitalizationData icon={mdiCakeVariant} value="10 years" />
    </Stack>
  )
}
