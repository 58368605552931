import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import z from 'zod'
import i18n from '../../../../app/i18n/i18n'
import { CustomFormTextField } from '../../../../components/CustomMUIForm/CustomFormTextField'
import { styles } from './NewClientForm.styles'

export const newClientFormSchema = z.object({
  name: z
    .string()
    .min(1, { message: `${i18n.t('name')} ${i18n.t('is_required')}` }),
  nif: z.string().optional(),
  phoneNumber: z
    .string()
    .optional()
    .transform((value) => value?.split(';')),
  notes: z.string().optional(),
})
export type NewClientFormSchema = z.infer<typeof newClientFormSchema>

export function NewClientForm() {
  const { t } = useTranslation()

  return (
    <Stack direction="column" sx={styles.form} spacing={2}>
      <CustomFormTextField name="name" label={t('name')} />
      <CustomFormTextField name="nif" label={t('nif')} />
      <CustomFormTextField name="phoneNumber" label={t('contact')} />
      <CustomFormTextField name="notes" label={t('notes')} />
    </Stack>
  )
}
