import { PassportApi, SuiteApi } from '@boommed-suite/contracts'
import { Strings } from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../../../domain/services/BoommedService'
import { useAppContext } from '../../../../app/contexts/AppContext'
import { CustomFormAsyncAutoComplete } from '../../../../components/CustomMUIForm/CustomFormAsyncAutoComplete'

export function PatientSearchField({
  name,
  label,
  client,
  required = false,
}: {
  name: string
  label: string
  client?: SuiteApi.ClientDetailResponse
  required?: boolean
}) {
  const { t } = useTranslation()
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()

  const fetchData = useCallback(
    async (searchTerm?: string | null) => {
      if (client) {
        const listLink = menu?.items?.pets._links?.list

        if (listLink !== undefined) {
          const [pagedClients] =
            await boommedService.fetch<PassportApi.ListUserPetsResponse>(
              listLink,
              undefined,
              {
                searchTerm: searchTerm ?? Strings.empty(),
                userId: client.userId ?? '',
              },
            )

          return pagedClients?.page.elements ?? []
        }
      }

      return []
    },
    [boommedService, client, menu],
  )

  return (
    <CustomFormAsyncAutoComplete
      name={name}
      label={label}
      loadingText={t('loading')}
      noOptionsText={t('patient_not_found')}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        option._links.self.href === value?._links.self.href
      }
      renderOption={(props, option) => {
        return (
          <li {...{ ...props, key: undefined }} key={option._links.self.href}>
            {option.name}
          </li>
        )
      }}
      fetchData={fetchData}
      required={required}
    />
  )
}
