import { Box } from '@mui/material'
import React from 'react'
import { FieldValues, FormProvider, Resolver, useForm } from 'react-hook-form'

export function CustomMUIForm<TFieldValues extends FieldValues = FieldValues>({
  resolver,
  children,
  onSubmit,
}: {
  resolver: Resolver<TFieldValues, unknown>
  children: React.ReactNode
  onSubmit: (data: TFieldValues) => void
}) {
  const methods = useForm<TFieldValues>({
    resolver,
  })

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        {children}
      </Box>
    </FormProvider>
  )
}
