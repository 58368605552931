import { Grid2, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TruncatedTypography } from '../../../../components/TruncatedTypography'

export function HospitalizationGeneralInfo() {
  const { t } = useTranslation()

  return (
    <Grid2
      container
      spacing={2}
      paddingX="12px"
      paddingBottom="12px"
      flexDirection="column"
    >
      <Grid2>
        <Typography fontWeight={700} lineHeight="16px" variant="h5">
          {t('hospitalization_general_info')}
        </Typography>
      </Grid2>
      <Stack width="195px" direction="row" whiteSpace="nowrap" spacing={2}>
        <Typography fontWeight={500} lineHeight="14px" variant="h6">
          {t('hospitalization_dvm')}
        </Typography>
        <TruncatedTypography
          fontWeight={400}
          lineHeight="14px"
          variant="h6"
          textAlign="right"
          flexGrow={1}
        >
          Some doctor very long name
        </TruncatedTypography>
      </Stack>
    </Grid2>
  )
}
