import { SuiteApi } from '@boommed-suite/contracts'
import { mdiScale } from '@mdi/js'
import MDIIcon from '@mdi/react'
import { InputAdornment, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import i18n from '../../../../app/i18n/i18n'
import { CustomFormAsyncAutoComplete } from '../../../../components/CustomMUIForm/CustomFormAsyncAutoComplete'
import { CustomFormDateTimePicker } from '../../../../components/CustomMUIForm/CustomFormDateTimePicker'
import { CustomFormTextField } from '../../../../components/CustomMUIForm/CustomFormTextField'
import { ClientSearchField } from './ClientSearchField'
import { styles } from './NewAppointmentForm.styles'
import { PatientSearchField } from './PatientSearchField'

export const newAppointmentFormSchema = z.object({
  client: z.record(z.unknown(), { message: i18n.t('client_is_required') }),
  patient: z.record(z.unknown(), { message: i18n.t('patient_is_required') }),
  symptoms: z
    .array(z.string(), { message: i18n.t('symptoms_is_required') })
    .optional(),
  diagnosticAreas: z
    .array(z.string(), { message: i18n.t('symptoms_is_required') })
    .optional(),
  medicalActs: z
    .array(z.string(), { message: i18n.t('symptoms_is_required') })
    .optional(),
  appointmentDate: z.number({
    message: i18n.t('appointment_date_is_required'),
  }),
  notes: z.string().optional(),
})
export type NewAppointmentFormSchema = z.infer<typeof newAppointmentFormSchema>

export function NewAppointmentForm() {
  const { t } = useTranslation()
  const [client, setClient] = useState<SuiteApi.ClientDetailResponse>()

  const onClientChange = (selectedClient) => {
    if (!(selectedClient instanceof Array)) {
      setClient(selectedClient ?? undefined)
    }
  }

  const fetchSymptoms = async (searchTerm?: string | null) => {
    return Promise.resolve(
      ['Symptom 1', 'Symptom 2', 'Symptom 3', 'Symptom 4', 'Symptom 5'].filter(
        (symptom) =>
          searchTerm
            ? symptom.toLowerCase().includes(searchTerm.toLowerCase())
            : true,
      ),
    )
  }

  const fetchDiagnosticAreas = async (searchTerm?: string | null) => {
    return Promise.resolve(
      [
        'Diagnostic Area 1',
        'Diagnostic Area 2',
        'Diagnostic Area 3',
        'Diagnostic Area 4',
        'Diagnostic Area 5',
      ].filter((symptom) =>
        searchTerm
          ? symptom.toLowerCase().includes(searchTerm.toLowerCase())
          : true,
      ),
    )
  }

  const fetchMedicalActs = async (searchTerm?: string | null) => {
    return Promise.resolve(
      [
        'Medical Acts 1',
        'Medical Acts 2',
        'Medical Acts 3',
        'Medical Acts 4',
        'Medical Acts 5',
      ].filter((symptom) =>
        searchTerm
          ? symptom.toLowerCase().includes(searchTerm.toLowerCase())
          : true,
      ),
    )
  }

  return (
    <Stack direction="column" sx={styles.form} spacing={2}>
      <ClientSearchField
        name="client"
        label={t('client')}
        onChange={onClientChange}
        required={true}
      />
      <PatientSearchField
        name="patient"
        label={t('patient')}
        client={client}
        required={true}
      />
      <CustomFormAsyncAutoComplete
        name="symptoms"
        label={t('symptoms')}
        loadingText={t('loading')}
        noOptionsText={t('symptoms_not_found')}
        getOptionLabel={(option) => option}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option) => {
          return (
            <li {...{ ...props, key: undefined }} key={option}>
              {option}
            </li>
          )
        }}
        fetchData={fetchSymptoms}
        multiple
      />
      <CustomFormAsyncAutoComplete
        name="diagnosticAreas"
        label={t('diagnostic_area')}
        loadingText={t('loading')}
        noOptionsText={t('diagnostic_area_not_found')}
        getOptionLabel={(option) => option}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option) => {
          return (
            <li {...{ ...props, key: undefined }} key={option}>
              {option}
            </li>
          )
        }}
        fetchData={fetchDiagnosticAreas}
        multiple
      />
      <CustomFormAsyncAutoComplete
        name="medicalActs"
        label={t('medical_act')}
        loadingText={t('loading')}
        noOptionsText={t('medical_act_not_found')}
        getOptionLabel={(option) => option}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option) => {
          return (
            <li {...{ ...props, key: undefined }} key={option}>
              {option}
            </li>
          )
        }}
        fetchData={fetchMedicalActs}
        multiple
      />
      <Stack direction="row" spacing={2}>
        <CustomFormDateTimePicker
          name="appointmentDate"
          label={t('date_time')}
          required
          ampm={false}
        />
        <CustomFormTextField
          name="weight"
          label={t('weight')}
          type="number"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <Stack spacing={1} direction="row">
                    <Typography variant="body2" component="h2">
                      {t('weight_kg')}
                    </Typography>
                    <MDIIcon path={mdiScale} size={1} />
                  </Stack>
                </InputAdornment>
              ),
            },
          }}
        />
      </Stack>
      <CustomFormTextField name="notes" label={t('notes')} multiline rows={4} />
    </Stack>
  )
}
