import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useService } from '../../../../hooks/useService'

interface MedicalProblem {
  description: string
}

function ProblemList() {
  const { data: problems = [] } = useService<MedicalProblem[]>(
    {
      service: async () => {
        return Promise.resolve<MedicalProblem[]>([
          {
            description: 'Prob 1',
          },
          {
            description: 'Prob 2',
          },
          {
            description: 'Prob 3',
          },
        ])
      },
    },
    [],
  )

  return (
    <Autocomplete
      options={problems}
      multiple
      getOptionLabel={(option) => option.description}
      limitTags={2}
      renderInput={(params) => <TextField {...params} size="small" />}
    />
  )
}

export function HospitalizationProblems() {
  const { t } = useTranslation()

  return (
    <Stack spacing={2} paddingX="12px" direction="column">
      <Typography fontWeight={700} lineHeight="16px" variant="h5">
        {t('hospitalization_problems')}
      </Typography>
      <ProblemList />
    </Stack>
  )
}
