import { Autocomplete, TextField } from '@mui/material'
import React, { useCallback, useState } from 'react'
import {
  Controller,
  FieldError,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form'
import { useDebounce } from '../../hooks/useDebounce'
import { useService } from '../../hooks/useService'

export type CustomFormAsyncAutoCompleteOnChangeHandler<T> = (
  value: T | readonly T[] | null,
) => void

export function CustomFormAsyncAutoComplete<
  OptionsType,
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  loadingText,
  noOptionsText,
  getOptionLabel,
  isOptionEqualToValue,
  renderOption,
  fetchData,
  onChange,
  required = false,
  debounceTime = 300,
  multiple = false,
}: {
  name: Path<TFieldValues>
  label?: string
  loadingText?: string
  noOptionsText?: string
  getOptionLabel: (option: OptionsType) => string
  isOptionEqualToValue: (option: OptionsType, value: OptionsType) => boolean
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement> & {
      key: unknown
    },
    option: OptionsType,
  ) => React.ReactNode
  fetchData: (searchTerm?: string | null) => Promise<OptionsType[]>
  onChange?: CustomFormAsyncAutoCompleteOnChangeHandler<OptionsType>
  required?: boolean
  debounceTime?: number
  multiple?: boolean
}) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>()

  const [searchTerm, setSearchTerm] = useState<string | undefined | null>()

  const { fetching, data: options } = useService<OptionsType[]>(
    {
      service: async () => {
        return await fetchData(searchTerm)
      },
    },
    [searchTerm, fetchData],
  )

  const debounceOnInputChange = useDebounce((input: string) => {
    setSearchTerm(input)
  }, debounceTime)

  const onInputChange = useCallback((_: unknown, newInputValue: string) => {
    debounceOnInputChange(newInputValue)
  }, [])

  const error = errors[name] as FieldError | undefined

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...register}
          loading={fetching}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={label}
              helperText={error?.message ?? ''}
              error={Boolean(error)}
            />
          )}
          options={options ?? []}
          loadingText={loadingText}
          noOptionsText={noOptionsText}
          isOptionEqualToValue={isOptionEqualToValue}
          onInputChange={onInputChange}
          onChange={(_, value) => {
            field.onChange(value)
            onChange?.(value)
          }}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          multiple={multiple}
        />
      )}
    />
  )
}
