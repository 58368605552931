import { mdiThermometer } from '@mdi/js'
import Icon from '@mdi/react'
import { Box, Stack, Typography } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useService } from '../../../../hooks/useService'

interface Temperature {
  day: number
  value: number
}

export function HospitalizationTemperatureHistory() {
  const { t } = useTranslation()

  const { data: temperature = [] } = useService<Temperature[]>(
    {
      service: async () => {
        return Promise.resolve<Temperature[]>([
          { day: 1, value: 2 },
          { day: 2, value: 1 },
          { day: 3, value: 4 },
          { day: 4, value: 1 },
          { day: 5, value: 1 },
          { day: 6, value: 3 },
        ])
      },
    },
    [],
  )

  const days = temperature.map((t) => t.day)
  const temperatures = temperature.map((t) => t.value)

  return (
    <Stack spacing={2} paddingX="12px" direction="column">
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon path={mdiThermometer} size={1} />
        <Typography fontWeight={700} lineHeight="16px" variant="h5">
          {t('hospitalization_temperature')}
        </Typography>
      </Stack>
      <Box width="195px" height="110px">
        <LineChart
          xAxis={[{ data: days }]}
          series={[
            {
              data: temperatures,
            },
          ]}
          width={265}
          height={175}
          sx={{
            left: '-30px',
            bottom: '40px',
            position: 'relative',
            '& .MuiChartsAxis-tickLabel': { fontSize: '8px !important' },
          }}
        />
      </Box>
    </Stack>
  )
}
