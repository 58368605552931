import { SuiteApi } from '@boommed-suite/contracts'
import { Strings } from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../../../domain/services/BoommedService'
import { useAppContext } from '../../../../app/contexts/AppContext'
import {
  CustomFormAsyncAutoComplete,
  CustomFormAsyncAutoCompleteOnChangeHandler,
} from '../../../../components/CustomMUIForm/CustomFormAsyncAutoComplete'

export function ClientSearchField({
  name,
  label,
  onChange,
  required = false,
}: {
  name: string
  label: string
  onChange?: CustomFormAsyncAutoCompleteOnChangeHandler<SuiteApi.ClientDetailResponse>
  required?: boolean
}) {
  const { t } = useTranslation()
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()

  const fetchData = async (searchTerm?: string | null) => {
    const listLink = menu?.items?.clients._links?.list

    if (listLink !== undefined) {
      const [pagedClients] =
        await boommedService.fetch<SuiteApi.ClientsListResponse>(
          listLink,
          undefined,
          { searchTerm: searchTerm ?? Strings.empty() },
        )

      return pagedClients?.page.elements ?? []
    }

    return []
  }

  return (
    <CustomFormAsyncAutoComplete
      name={name}
      label={label}
      loadingText={t('loading')}
      noOptionsText={t('client_not_found')}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderOption={(props, option) => {
        return (
          <li {...{ ...props, key: undefined }} key={option.id}>
            {option.name}
            <br />
            {option.phoneNumber?.reduce(
              (acc, phone, index) =>
                index === 0 ? phone : `${acc} / ${phone}`,
              '',
            )}
          </li>
        )
      }}
      onChange={onChange}
      fetchData={fetchData}
      required={required}
    />
  )
}
