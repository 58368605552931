import { Grid2, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TruncatedTypography } from '../../../../components/TruncatedTypography'

function Detail({ label, value }: { label: string; value: string }) {
  return (
    <Stack width="195px" direction="row" whiteSpace="nowrap" spacing={2}>
      <Typography fontWeight={500} lineHeight="14px" variant="h6">
        {label}
      </Typography>
      <TruncatedTypography
        fontWeight={400}
        lineHeight="14px"
        variant="h6"
        textAlign="right"
        flexGrow={1}
      >
        {value}
      </TruncatedTypography>
    </Stack>
  )
}

export function HospitalizationClientDetail() {
  const { t } = useTranslation()

  return (
    <Grid2 container spacing={2} paddingX="12px" flexDirection="column">
      <Grid2>
        <Typography fontWeight={700} lineHeight="16px" variant="h5">
          {t('client')}
        </Typography>
      </Grid2>
      <Detail label={t('client_name')} value="John Doe John Doe John Doe" />
      <Detail label={t('client_phone_number')} value="07711 419411" />
    </Grid2>
  )
}
