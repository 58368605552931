import { CustomPalette, theme } from '../../../../app/Theme'

export const styles = {
  root: {
    background: CustomPalette.master.darkGrey,
    height: '64px',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: CustomPalette.master.white,
  },
  icon: {
    color: CustomPalette.master.white,
    width: '18px',
    height: '16px',
  },
  iconContainer: {
    backgroundColor: CustomPalette.master.selectedGrey,
    borderRadius: '26px',
    width: '30px',
    height: '30px',
  },
}
