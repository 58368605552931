import { zodResolver } from '@hookform/resolvers/zod'
import { mdiClose } from '@mdi/js'
import MDIIcon from '@mdi/react'
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  AppRoutes,
  buildNavigationPath,
  useAppNavigate,
} from '../../../../app/AppRouter'
import { useAppContext } from '../../../../app/contexts/AppContext'
import { theme } from '../../../../app/Theme'
import { CustomMUIForm } from '../../../../components/CustomMUIForm/CustomMUIForm'
import { SuiteButton } from '../../../../components/SuiteButton/SuiteButton'
import {
  NewAppointmentForm,
  NewAppointmentFormSchema,
  newAppointmentFormSchema,
} from '../NewAppointmentForm/NewAppointmentForm'
import { styles } from './NewAppointment.styles'

const NewAppointmentHeader = () => {
  const { t } = useTranslation()
  const navigate = useAppNavigate()

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack direction="row" sx={styles.header}>
      <Typography variant="h4" flexGrow={1} fontWeight="bold">
        {t('appointment_new_title')}
      </Typography>
      <Box sx={styles.closeButton} onClick={onClose}>
        <MDIIcon path={mdiClose} size={0.75} />
      </Box>
    </Stack>
  )
}

const NewAppointmentFooter = () => {
  const { t } = useTranslation()

  return (
    <Stack
      sx={styles.footerContainer}
      direction="row"
      spacing={theme.spacing(2)}
    >
      <Box sx={styles.footerDiscardContainer}>
        <Button variant="text">{t('discard')}</Button>
      </Box>
      <FormGroup>
        <FormControlLabel
          sx={styles.footerHospitalizationLabel}
          control={<Switch defaultChecked={true} disabled />}
          label={t('hospitalization')}
        />
      </FormGroup>
      <SuiteButton variant="contained" type="submit">
        {t('appointment_new_create')}
      </SuiteButton>
    </Stack>
  )
}

export const NewAppointment = () => {
  const navigate = useAppNavigate()
  const { tenant } = useAppContext()
  const { action: routeAction } = useParams()

  const onSubmit = (data: NewAppointmentFormSchema) => {
    // TODO: Create appointment calling the api
    // TODO: Redirect to hospitalization detail page with the new hospitalization id

    // eslint-disable-next-line no-console
    console.log('Create appointment data:', data)

    const link = buildNavigationPath(AppRoutes.hospitalizationDetail, {
      tenantId: tenant?.id ?? '',
      hospitalizationId: 'some-hospitalization-id',
    })
    navigate(link)
  }

  return (
    <Drawer
      anchor="right"
      open={routeAction === 'add'}
      sx={styles.drawer}
      variant="persistent"
    >
      <Box sx={styles.container}>
        <NewAppointmentHeader />
        <CustomMUIForm
          resolver={zodResolver(newAppointmentFormSchema)}
          onSubmit={onSubmit}
        >
          <NewAppointmentForm />
          <NewAppointmentFooter />
        </CustomMUIForm>
      </Box>
    </Drawer>
  )
}
